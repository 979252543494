@import "../../../styles/index";

.contractorCard {
  @include formSectionStyles();
  background-color: white;
  padding: 10px;

  &__title {
    padding: 10px;
    font-size: 18px;
    color: $light-blue;
    text-transform: uppercase;
    font-weight: bold;
  }
}

