@import "../../../styles/index.scss";

.progressLine {
  &__description {
    color: #47484b;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    p {
    padding-bottom: 10px;
    }
  }
}

