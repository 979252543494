@import "../../../../../styles/index";

.paidCourse {
  font-family: $open-sans;
  background: $order-background;
  color: #47484b;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &__item {

  }

}