.messages {
  width: fit-content;
  max-width: 60%;
  margin-bottom: 2px;
  padding: 1em;
  border-radius: 1em;
  overflow-wrap: break-word;

  &.myMessage {
    background-color:#0095ff;
    color: #fff;
    border-radius: 1em;
    border: 1px solid #8d8d8d;
    box-shadow: 3px 3px 7px -2px #838383;
  }
  &.otherMessage {
    border: 1px solid #ccc;
    box-shadow: -3px 3px 7px -2px #838383;
  }
}