@import "../../../../../styles/index.scss";

.orderTrain, .orderTrain__touched {
  min-height: $order-input-height;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
  margin: 5px;
  padding: 5px;

  &__touched {
    border-left: 3px solid #1dbbed;
  }
}
