@import "../../../styles/index";

.driversMap {
  @include formSectionStyles();
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  background-color: white;

  &__description {
    margin-bottom: 20px;

    &--title {
      margin: 0;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subtitle {
      font-size: 11px;
      color: #47484b;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;
      margin: 15px 0;

      &--legendItem {
        margin-right: 20px;
        display: flex;
        align-items: center;

        &--title {
          font-size: 11px;
          text-transform: uppercase;
          color: #47484b;
          line-height: 22px;
        }

        &--driverPoint, &--startPoint, &--metaPoint {
          width: 22px;
          height: 22px;
          margin-right: 3px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          background-image: url("../../../img/zielony_pin.png");
        }

        &--metaPoint {
          background-image: url("../../../img/niebieski_pin.png");
        }

        &--driverPoint {
          width: 28px;
          height: 28px;
          margin-right: 5px;
          background-image: url("../../../img/car (1).png");
        }


      }
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;


    &--single {

    }
  }

  &__map {
    width: 100%;
    height: 65vh;
  }
}