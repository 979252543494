.messageInfo {
  color: #999;
}
.systemMessage {
  padding: 1em;
  border-radius: 1em;
  text-align: center;

  &.success {
    color: #fff;
    background: #5ab71b;
  }

  &.error {
    color: #fff;
    background: #b71b32;
  }

  &.warning {
    color: #fff;
    background: #f35202;
  }

  &.info {
    color: #fff;
    background: #2ac0f6;
  }
}