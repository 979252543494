@import "../../../../styles/index";

.contractorCardForm {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1130px;
    margin-left: 10px;

    &__form {
        width: 560px;
        padding: 10px;
        margin-bottom: 16px;
        border: 1px solid #d7d7d7;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

        &--title {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;
        }

        &--inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    &--buttonWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
    }

    &__description {
        margin: 30px 0 0;
        font-weight: 700;
    }

    &__dates {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--description {
            margin: 50px 0 0;
            font-weight: 700;
        }

        &--single {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &--description {
                margin: 0 5px 0 0;
            }
        }
    }

    &__inputs {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        &--notes {
            margin: 20px 0 0;
            flex-basis: 100%;

            &--title {
                font-size: 15px;
                text-transform: uppercase;
                font-weight: bold;
                margin: 10px 0;
            }
        }

        &--wrapper {
            margin: 20px 20px 0 0;
            flex-basis: 25%;

            &--title {
                font-size: 13px;
                text-transform: uppercase;
                font-weight: bold;
                margin: 16px 0 0;
            }

            &--single,
            &--switch {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                min-width: 150px;

                &--description {
                    margin: 0 5px 0 0;
                }

                &--lastDescription {
                    margin: 0 5px 0 5px;
                }
            }

            &--switch {
                align-items: center;
                padding: 13px 0 7px;
            }
        }
        
        &--double-wrapper {
            margin: 20px 20px 0 0;
            flex-basis: 59.5%;

            &--title {
                font-size: 13px;
                text-transform: uppercase;
                font-weight: bold;
                margin: 16px 0 0;
            }

            &--single,
            &--switch {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                min-width: 150px;

                &--description {
                    margin: 0 5px 0 0;
                }

                &--lastDescription {
                    margin: 0 5px 0 5px;
                }
            }

            &--switch {
                align-items: center;
                padding: 13px 0 7px;
            }
        }
    }

    &__buttonWrapper {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
