@import "../../../../styles/index.scss";

.infoDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;

    &:hover {
      color: #00a0e3;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &--buttonWrapper {
      margin-top: 13px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
