@import "../../../styles/index.scss";

.addAddress {
  @include formSectionStyles();
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &__description {
    &--title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
    }
    &--close {
      position: absolute;
      font-size: 18px;
      top: 10px;
      right: 10px;
      cursor: pointer;
      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__wrapper {
    display: flex;

    &--form, &--map {
      &--description {
        &--title {
          margin: 0;
          font-size: 18px;
          color: $light-blue;
          text-transform: uppercase;
          font-weight: bold;
        }

        &--subTitle {
          margin-top: 0;
          font-size: 11px;
        }
      }
    }

    &--form {
      margin-right: 15px;
      width: 300px;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

      &--search,
      &--fieldGroup {
        margin-bottom: 16px;

        &--field {
          margin: 5px;
        }
      }
    }
  }
}
