@import "../../../../../../styles/index.scss";

.addKontrahentForm {
  position: relative;
  display: flex;
  flex-direction: column;

  &__description {
    &--title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
    }

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 18px;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__inputs {
    margin: 5px 0 10px;
    padding: 5px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

    &--fieldWrapper {
      display: flex;
      justify-content: space-between;

      &--field {
        margin: 5px;

      }
    }
  }
}
