@import "../../../../../styles/index.scss";

.orderDistance {
  display: flex;
  justify-content: space-around;
  margin: 5px;
  padding: 5px;


  &__map {
    outline: 0 !important;
    width: 80vw;
    height: 87vh;
    position: relative;

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 26px;
      color: #313131;
      z-index: 100;
      cursor: pointer;
    }
  }

  &__buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &--title, &--data {
      padding: 2px;
      font-size: 14px;
      color: #b4b4b4;
      font-weight: bold;
      margin: 0;

      &--shortTime {
        color: #ED1D1D;
      }
    }
  }
}
