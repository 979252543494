.messagePanel {
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(3, minmax(300px, 100%));
  grid-column-gap: 1em;
  overflow: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar{
    height: 13px
  }
  &::-webkit-scrollbar-track {
    padding:10px;
    margin: 10px
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.hidden {
    display: none;
  }
}
