.historyItem {
  border: none;
  box-shadow: none;
  padding: 0;

  &--wrapper {
    flex-direction: column;
    margin: 0;
    padding: 0;
    min-width: 250px;
    max-width: 280px;

    &--time,
    &--courseDetails,
    &--wrapper {
      flex-direction: column;
    }

    &--history {
      padding: 0;
      margin: 0;

      &--details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--title {
          margin: 0 0 3px 0;
          font-size: 13px;
          color: #47484b;
          text-transform: uppercase;
          font-weight: 600;

          &--history {
            margin: 0 0 3px 0;
            font-size: 13px;
            color: #1dbbed;
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        &--singleColumn {
          margin-bottom: 10px;

          &--info {
            margin: 0;
            color: #6f6f6f;
            font-size: 12px;
            font-weight: bold;
          }

          &--data {
            margin: 0 0 4px 0;
            font-size: 12px;
            font-weight: bold;
            max-width: 250px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;

            &--history {
              margin: 0 0 4px 0;
              font-size: 12px;
              font-weight: bold;
              color: #1dbbed;
            }

            &--edited {
              margin: 0 0 2px 0;
              font-size: 12px;
              font-weight: bold;
              max-width: 250px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
              //color: #FF650F;
            }
          }
        }
      }
    }
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;

  &--routeButton {
    position: absolute !important;

  }
}