@import "../../../../styles/index";

.conversationModal {
  color: #47484b;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;


  &__description {
    position: relative;
    padding-top: 10px;
    width: 320px;

    &--title {
      text-transform: uppercase;
      color: $light-blue;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      margin: 0;
      padding: 0 0 15px 15px;
    }

    &--close {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__wrapper {
    width: 320px;
  }
}
