@import "../../../../styles/index";

.importOrders {
  @include formSectionStyles();
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;


  &__description {
    padding: 10px;

    &--title {
      margin: 0;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  & input[type="file"] {
    display: none;
  }

  &__upload {
    margin: 10px 10px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    color: #b4b4b4;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #777777;
    }
  }

  &__response {
    margin: 10px 10px 10px 5px;

    &--success {
      display: flex;
      align-items: center;
      color: #66D01D;
      font-size: 15px;
      font-weight: bold;

      &--button {
        padding-left: 5px;
        text-decoration: underline;
        cursor: pointer;
        color: #b4b4b4;
      }
    }

    &--title {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: bold;
      color: #FF650F;
    }

    &--info {
      padding: 0 5px;
      font-weight: bold;
      color: $font-color;
    }

    &--data {
      font-weight: normal;
      color: #777777;
    }
  }
}
