@import "../../../../../styles/index.scss";

.formNotes {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 450px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

  &__input {
    padding: 5px 5px 10px;
    width: 100%;

    &--link {
      margin-left: 5px;
      text-decoration: underline;
      font-weight: normal;
      font-size: 10px;
      margin-bottom: 8px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #1dbbed;
      }
    }
  }
}
