@import "../../../../../styles/index";

.conversation {
  color: #47484b;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  &__description {
    position: relative;

    &--title {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      margin: 0;
      padding: 5px 0;
    }

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__inputs {
    margin: 5px 10px;
    padding: 5px;
    color: #47484b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--conversation {
      padding-right: 10px;

      &--dispatch, &--operator, &--accepted {
        margin: 5px 50px 12px 5px;
        padding: 5px;
        border-left: 3px solid #faba1f;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);
        text-align: left;

        &--info {
          margin: 0;
          color: #6f6f6f;
          font-size: 11px;
        }

        &--content {
          margin: 4px 0;
          font-size: 12px;
          font-weight: bold;
          max-width: 250px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
      }

      &--operator {
        margin: 5px 5px 12px 50px;
        text-align: right;
        border-left: none;
        border-right: 3px solid $light-blue;
      }

      &--accepted {
        color: #66D01D;
        margin: 5px 25px 12px 25px;
        padding: 5px;
        border: none;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);
        text-align: center;
      }

      &--buttonWrapper {
        display: flex;
        align-self: center;
        justify-content: flex-end;
      }

      &--answearPanel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 5px;
      }
    }


  }
}