@import "../../../../../styles/index.scss";

.orderHome {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__singleLine, &__singleLine__deleted, &__singleLine__new, &__singleLine__touched {
    min-height: $order-input-height;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
    margin: 5px;
    padding: 5px;

    &__deleted {
      border-left: 3px solid red;
    }

    &__new {
      border-left: 3px solid lightgreen;
    }
    &__touched {
      border-left: 3px solid #1dbbed;
    }

    &--link {
      color: $font-color;
      text-decoration: underline;
      font-weight: normal;
      font-size: 10px;
      margin-bottom: 8px;
      margin-top: 0;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #1dbbed;
      }
    }
  }
}
