@import "../../../../../../styles/index.scss";

.addWorkerForm {
  color: #47484b;
  position: relative;
  display: flex;
  height: 95%;

  &__description {
    position: relative;

    &--title {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
    }

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }
}