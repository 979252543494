@import "../../../../styles/index";

.confirmation {
  color: #47484b;
  position: relative;
  display: flex;
  height: 92%;

  &__description {
    &--title {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__map {
    flex-grow: 1;
    height: 80vh;
    min-width: 400px;
    width: 100%;
    margin: 5px 0 10px;
  }

  &__inputs {
    margin-left: 10px;
    color: #47484b;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &--item {
      overflow-x: auto;
      overflow-y: auto;
      min-height: 70px;
    }

    &--item, &--item--showPanel, &--item--distanceView {
      margin: 5px 0 10px;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--title {
        margin: 5px 0;
        font-size: 15px;
        color: #47484b;
        text-transform: uppercase;
        font-weight: 600;
      }

      &--hidePanel {
        &--distanceWrapper {
          display: none;

          &--distances {
            &--single {
            }
          }
        }
      }

      &--courseDetails {
        display: flex;
        justify-content: space-between;

        &--details {
          min-width: 250px;

          &--info {
            margin: 5px 0 0;
            color: $light-blue;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
          }

          &--data {
            margin: 0;
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 10px;
          }
        }
      }

      &--showPanel {
        &--distanceWrapper {
          display: block;
          &--distances {
            display: flex;
            justify-content: flex-start;
            &--single {
              margin-right: 20px;


              &--info {
                margin: 0;
                color: $light-blue;
                font-size: 12px;
                font-weight: bold;
                text-align: center;
              }

              &--data, &--data--active {
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;

                &--1, &--2, &--4 {
                  padding: 10px 5px;
                  border: 1px solid #d7d7d7;
                  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);
                  color: #C2C2C2;
                  font-weight: 600;
                  font-size: 20px;
                  text-align: center;
                  cursor: pointer;
                }

                &--3 {
                  padding: 16px 5px;
                  color: #ED1D1D;
                  font-weight: 600;
                  font-size: 20px;
                  text-align: center;
                }

                &--4 {
                  padding: 4px 5px;
                  margin: 20px 0;
                }

                &--1:hover, &--2:hover, &--4:hover {
                  color: #47484b;
                  border: 3px solid #66D01D;
                  border-radius: 5px;
                }

                &--active {
                  &--1, &--2, &--4 {
                    color: #47484b;
                    border: 3px solid #66D01D;
                    border-radius: 5px;
                  }
                }
              }
            }
          }

          &--buttonWrapper {
            display: flex;
            justify-content: flex-start;
          }
        }
      }

      &--distanceView, &--history {
        &--noData {
          font-weight: normal;
          text-transform: none;
        }

        &--details {
          display: flex;
          padding: 5px 0;

          &--singleColumn {
            min-width: 250px;

            &--info {
              margin: 2px 0 0;
              color: $light-blue;
              text-transform: uppercase;
              font-size: 12px;
              line-height: 14px;
              font-weight: bold;
            }

            &--data {
              margin: 0;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              padding-bottom: 10px;
            }
          }
        }
      }

      &--history {
        max-height: 270px;

        &--reverse {
          display: flex;
          flex-direction: column-reverse;
        }

        &--details {
          border-bottom: 1px solid #d7d7d7;
        }
      }

      &--address, &--status, &--history {
        position: relative;

        &--info {
          margin: 0;
          color: $light-blue;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 14px;
          font-weight: bold;
        }

        &--data {
          margin: 0 0 5px 0;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
        }

        &--buttonWrapper {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}


