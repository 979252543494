@import "../../../../styles/index";

.driversMapComponent {
  min-width: 500px;
  padding: 10px;
  height: auto;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: auto;
  z-index: 9999; /* Watch out for this!!! */

  &__closer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    z-index: 99999;

    &:hover {
      color: #00a0e3;
    }
  }
}