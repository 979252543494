@import "../../../../styles/index.scss";

.addAddress {
  @include formSectionStyles();
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;

  .addAddressForm {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &__description {
      &--title {
        font-size: 18px;
        color: $light-blue;
        text-transform: uppercase;
        font-weight: bold;
      }

      &--subTitle {
        font-size: 11px;
      }
    }

    &__map {
      height: 620px;
      width: 600px;
      margin-bottom: 10px;
    }

    &__personal,
    &__address,
    &__contact,
    &__regNo,
    &__rate, {
      padding: 10px;
      margin-bottom: 10px;
      background: $order-background;
      border: 1px solid #efefef;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--description {
        margin-left: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }

      &--inputs {
        display: flex;
        justify-content: space-around;
        margin-bottom: 16px;

        &--field {
          margin: 5px;

          &--checkbox {
            width: 100%;
          }

        }
      }
    }

    &__regNo {
      width: 100%;
      &--inputs {
        &--field {
          width: 100%;
        }
      }
    }

    &__rate {
      width: 100%;
      &--inputs {
        display: flex;
        justify-content: flex-start;
        &--field {
          width: 50%;
        }
      }
    }

    &__address {
      &--inputs {
        flex-direction: column;

        &--fieldGroup {
          display: flex;
          justify-content: space-around;

          &--field {
            margin: 5px;
            flex-basis: 50%;
            flex-grow: 1;
          }
        }
      }

      &--buttonWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;

        &--description {
          font-size: 11px;
          font-weight: normal;
          line-height: 30px;
          color: #47484b;
        }

        &--addAddressButton,
        &--removeAddressButton {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2px;
          height: 30px;
          width: 30px;
          border: none;
          border-radius: 15px;
          background-color: #b4b4b4;
          color: white;
          transition: 0.3s;

          &:hover {
            background-color: #777777;
          }
        }

        &--removeAddressButton {
          background-color: #777777;

          &:hover {
            background-color: black;
          }
        }
      }
    }

    &__buttonWrapper {
      display: flex;
      justify-content: flex-start;
    }
  }
}
