.messageGroup {
  display: flex;
  flex-direction: column;

  &.myGroup {
    align-items: flex-end;
    margin-left: auto;
  }

  &.otherGroup {
    margin-right: auto;
  }
}

.messageGroup:not(:first-of-type) {
  .divider {
    height: 1em;
  }
}