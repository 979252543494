@import "../../../../../styles/index.scss";

.singleSchedule {
  width: 100%;

  &__description {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--legendItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--singleLegend {
        margin-right: 15px;
        font-size: 11px;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &--icon {
          height: 1em;
          width: 1em;
          margin-right: 5px;
          border-radius: 25px;
          background-image: url("./../../../../../img/availableIcon.png");
          background-position: center;
          background-size: cover;
        }
      }
    }
  }

  &__dateSwitcher {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 5px;

    &--calendarIcon {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      background-image: url("./../../../../../img/calendar.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__singleLine {
    display: flex;
    justify-content: stretch;
    width: 100%;

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 5px;
      border: 1px solid #d7d7d7;
    }

    &--schedule {
      flex-grow: 1;
      position: relative;
      border: 1px solid #d7d7d7;
      background-image: url("../../../../../img/scheduleThinLine.svg");
      background-position: center;
      background-size: contain;

      &--hours {
        position: absolute;
        bottom: 0;
        width: 100%;

        &--item {
          font-size: 12px;
          position: absolute;
          top: 0;
          z-index: 5;
        }
      }

      &--onRoute,
      &--wait,
      &--dayOff,
      &--rest {
        position: absolute;
        top: 0;
        height: 100%;
      }

      &--onRoute {
        background-color: $light-blue;
      }

      &--dayOff {
        background-color: #d7d7d7;
      }
    }
  }
}

