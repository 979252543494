@import "../../../../styles/index.scss";

.orderLine {
  &__wrapper {
    border: none;
    border-left: 2px solid $light-blue;
    margin-top: 10px;
    padding: 20px;
    background: $order-background;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    &--operator {
      display: flex;
      padding: 5px 0 30px 0;
      &--item {
        padding: 0 8px;
      }
    }

    &--form {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--default {
      background: rgba(45, 187, 237, 0.05);
      border: none;
    }

    &--legend {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      &--item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &--title {
          font-size: 18px;
          margin-right: 30px;
          color: $light-blue;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    &--isReturn,
    &--propositionFromDestination,
    &--subOrder {
      border: none;
      margin-top: 10px;
      padding: 20px;
      margin-left: 100px;
      background-color: $order-background;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

      &--legend {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--item {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &--title {
            margin-right: 30px;
            line-height: 30px;
            padding: 5px;
            font-size: 16px;
            color: $light-blue;
            text-transform: uppercase;
            font-weight: bold;
          }

          &--description {
            padding: 5px;
            font-size: 14px;
          }
        }
      }
    }

    &--subOrder {
      border-left: 1px solid $light-blue;
      background-color: $order-background;
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--acceptButtonWrapper,
    &--removeButtonWrapper,
    &--returnButtonWrapper,
    &--confirmButtonWrapper,
    &--previousShiftButtonWrapper {
      display: flex;
      justify-content: stretch;
      align-items: center;
      margin: 5px 0 5px 10px;

      &--description {
        padding: 5px;
        font-size: 11px;
        font-weight: normal;
        line-height: 30px;
        color: #47484b;
      }

      &--returnButton,
      &--removeButton,
      &--acceptButton,
      &--confirmButton,
      &--previousShiftButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2px;
        height: 30px;
        width: 30px;
        border: none;
        border-radius: 18px;
        background-color: $light-blue;
        color: white;
        transition: 0.3s;

        &:hover {
          background-color: #00a0e3;
        }
      }
    }

    &--confirmButtonWrapper {
      display: none;
    }

    &--isReturn,
    &--propositionFromDestination,
    &--subOrder {
      &--acceptButtonWrapper,
      &--removeButtonWrapper,
      &--confirmButtonWrapper,
      &--previousShiftButtonWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0 5px 10px;

        &--description {
          padding: 5px;
          line-height: 30px;
          font-size: 11px;
          font-weight: normal;
          color: #47484b;
        }
      }

      &--acceptButtonWrapper,
      &--previousShiftButtonWrapper {
        display: none;
      }
    }
  }
}

.orderLine__wrapper--isReturn > .orderLine__buttonWrapper > .orderLine__buttonWrapper--previousShiftButtonWrapper,
.orderLine__wrapper--propositionFromDestination > .orderLine__buttonWrapper > .orderLine__buttonWrapper--previousShiftButtonWrapper,
.orderLine__wrapper--subOrder > .orderLine__buttonWrapper > .orderLine__buttonWrapper--previousShiftButtonWrapper, .orderLine__buttonWrapper--subOrder--confirmButtonWrapper {
  display: none;
}
