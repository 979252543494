@import "../../../styles/index.scss";

.urlopy {
  @include formSectionStyles();
  background-color: white;

  &__description {
    padding: 10px;

    &--title {
      margin: 0;
      padding-right: 30px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--button {
      margin: 20px 0 0;
    }

    &--subTitle {
      margin: 0;
      font-size: 11px;
      color: #47484b;
    }
  }

  &__wrapper {
    padding: 10px 10px 30px 10px;

    &--inputs, &--dayList {

      &--description {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: $font-color;
      }

      &--singleLine {
        border: none;
        padding: 10px 10px 10px 0;
        background: $order-background;
        display: flex;

        &--buttonWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }


      }


    }


  }


}
