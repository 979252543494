@import "../../../styles/index";

.rozliczenia {
  @include formSectionStyles();
  margin: 0;
  min-height: 0;
  max-height: none;
  padding-bottom: 0;

  &__noData {
    min-width: 200px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  &__description {
    padding: 10px;

    &--title {
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      font-size: 11px;
      color: #47484b;
    }
  }

  &__buttonContainer {
    display: flex;
    align-items: center;

    &--mapButton,
    &--moreButton,
    &--removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border: none;
      border-radius: 15px;
      background-color: #1DBBED;
      color: white;
      transition: 0.3s;

      &:hover {
        background-color: #00A0E3;
      }
    }

    &--disabledButton {
      background-color: #e0e0e0 !important;
    }

    &--removeButton {
      background-color: #C01313;

      &:hover {
        background-color: black;
      }
    }
  }
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader {
  padding: 5px 5px 5px 10px !important;
  background: rgba(243, 243, 243, 0.7);
  box-shadow: none !important;
  color: #47484b;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2;
  text-align: left !important;

}

.MuiTableCell-root.MuiTableCell-body {
  padding: 5px 5px 5px 10px !important;
  text-align: left;
}
