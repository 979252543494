@import "../../../../../styles/index.scss";

.driverDetails {
  color: #47484b;
  position: relative;
  display: flex;
  justify-content: stretch;
  overflow-y: auto;
  height: 100%;

  &__map {
    flex-grow: 1;
    min-width: 250px;
    height: 480px;
    padding: 10px 0 10px 20px;
  }

  &__data {
    &--noData {
      padding: 5px;
      background-color: white;
      color: #6e6e6e;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }

    &--description {
      border: none;

      &--title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }

      &--close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          color: #00a0e3;
        }
      }
    }

    &--item {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--id {
        margin: 0;
        color: $light-blue;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }

      &--idInfo {
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }

      &--wrapper {
        &--person,
        &--contact,
        &--address {
          min-width: 250px;
        }

        &--title {
          margin: 5px 0;
          font-size: 15px;
          line-height: 20px;
          color: #47484b;
          text-transform: uppercase;
          font-weight: 600;
        }

        &--singleData {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #3eccf9;
        }

        &--info {
          margin: 0 0 5px 0;
          padding: 0;
          font-weight: bold;
          font-size: 14px;
          color: #575b55;
        }
      }
    }
  }
}
