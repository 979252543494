@import "../../../styles/index.scss";

.zestawienie {
  @include formSectionStyles();
  min-height: 90vh;
  max-height: none;

  &__noData {
    min-width: 200px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  &__description {
    padding: 10px 10px 30px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--box {
      display: flex;
      align-items: center;
    }

    &--title {
      font-size: 18px;
      padding-right: 30px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      font-size: 11px;
      color: #47484b;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;

      &--legendItem {
        margin-right: 15px;
        display: flex;
        align-items: center;

        &--singleLegend {
          font-size: 11px;
          text-transform: uppercase;
          color: #47484b;
        }
      }
    }
  }

  &__table {
    &--item {
      margin: 0;
      padding: 0;
      color: #47484b;

      &--direction {
        display: flex;
        align-items: center;
        padding: 5px;

        &--toWork, &--toHome, &--icon {
          width: 22px;
          height: 22px;
          margin-right: 10px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &--toHome {
          background-image: url("../../../img/home_icon.svg");
        }

        &--toWork {
          background-image: url("../../../img/work_icon.svg");
        }
      }


    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: nowrap;

    &--mapButton,
    &--moreButton,
    &--acceptButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border: none;
      border-radius: 15px;
      background-color: #1DBBED;
      color: white;
      transition: 0.3s;

      &:hover {
        background-color: #00A0E3;
      }
    }

    &--acceptButton {
      background-color: #66D01D;

      &:hover {
        background-color: #5AB71B;
      }

      &:disabled {
        background-color: #cccccc;
      }
    }
  }

  .MuiTableCell-root.MuiTableCell-body {
    font-family: $open-sans;
    padding: 5px;

    span {
      color: #47484b;
    }

    p {
      padding: 5px;
      margin: 0;
    }
  }
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader {
  padding: 10px 10px 10px 0;
  background: rgba(243, 243, 243, 0.7);
  box-shadow: none !important;
  color: #47484b;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
}
