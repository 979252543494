@import "../../../../styles/index.scss";

.urlopyTable {
  @include formSectionStyles();

  &__noData {
    min-width: 200px;
    padding: 10px 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
  }

  &__table {
    &--item {
      margin: 0;
      padding: 0;
      color: #47484b;
      display: flex;
      flex-direction: column;

      &--single {
        padding: 5px;
      }


    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: nowrap;
  }
}
