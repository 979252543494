@import "../../../styles/index.scss";

.workInProgress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  width: 500px;
  height: 300px;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  &__title {
    font-family: $open-sans;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: $font-color;
  }

  &__icon {
    height: 150px;
    width: 150px;
    background-image: url("../../../img/Group 282.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
