@import "../../../../../styles/index.scss";

.formCompanyInfo {
  display: flex;
  justify-content: space-between;
  width: 450px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
  padding-bottom: 10px;
}
