@import "../../../../../styles/index";

.mapPopup {
  color: #47484b;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
  height: 100%;


  &__description {
    display: flex;

    &--title {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__inputs, &__schedule {
    display: flex;
    flex-direction: column;

    &--item, &--schedule {
      margin: 5px 0;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--title {
        margin: 3px 0;
        font-size: 14px;
        color: #47484b;
        text-transform: uppercase;
        font-weight: 600;
      }

      &--wrapper {
        display: flex;

        &--singleColumn {
          min-width: 250px;

          &--info {
            margin: 0;
            color: $light-blue;
            font-size: 11px;
            font-weight: bold;
          }

          &--data {
            margin: 0;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }

    &--schedule {
      &--wrapper {
        flex-direction: column;
      }
    }
  }
}