@import "../styles/index";

.activityLog {
  min-height: 100%;

  &__wrapper {
    padding: 0 10px;

    &--item {
      &--date {
        margin: 0 0 4px 0;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }

      &--author {
        margin: 0 0 4px 0;
        font-size: 14px;
      }

      &--content {
        font-weight: bold;
        color: $light-blue;


      }
    }
  }

  &--historyItem {
    border: none;
    box-shadow: none;
    padding: 0;

    &--wrapper {
      flex-direction: column;
      margin: 0;
      padding: 0;
      min-width: 250px;
      max-width: 280px;

      &--time, &--courseDetails, &--wrapper {
        flex-direction: column;
      }

      &--time {
        display: flex;
        justify-content: flex-start;

        &--single, &--from, &--to {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 20px 10px 0;
          min-width: 250px;


          &--data {
            font-weight: bold;
            font-size: 13px;
            color: #575b55;

            &--edited {
              margin: 0 0 2px 0;
              font-size: 12px;
              font-weight: bold;
              //color: #FF650F;
            }
          }

          &--toWork, &--toHome {
            width: 22px;
            height: 22px;
            margin-right: 5px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        &--from {
          &--toHome {
            background-image: url("../img/home_icon.svg");
          }

          &--toWork {
            background-image: url("../img/work_icon.svg");
          }
        }

        &--to {
          &--toHome {
            background-image: url("../img/home_icon.svg");
          }

          &--toWork {
            background-image: url("../img/work_icon.svg");
          }
        }
      }

      &--history {
        padding: 0;
        margin: 0;

        &--details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &--title {
            margin: 0 0 3px 0;
            font-size: 13px;
            color: #1DBBED;
            text-transform: uppercase;
            font-weight: 600;

            &--history {
              margin: 0 0 3px 0;
              font-size: 13px;
              color: #1DBBED;
              text-transform: uppercase;
              font-weight: 600;

            }
          }

          &--singleColumn {
            margin-bottom: 10px;

            &--info {
              margin: 0;
              color: #6f6f6f;
              font-size: 12px;
              font-weight: bold;
            }

            &--data {
              margin: 0 0 4px 0;
              font-size: 12px;
              font-weight: bold;
              max-width: 230px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;

              &--history {
                margin: 0 0 4px 0;
                font-size: 12px;
                font-weight: bold;
                color: #1DBBED;
              }
            }
          }
        }
      }
    }
  }
}