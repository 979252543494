@import "../../../../../../styles/index";

.messageColumn {
  color: #47484b;
  position: relative;
  display: flex;
  min-height: 650px;
  max-height: 700px;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .newMessage {
    margin-left: 1em;
    color: #3ae400;
    cursor: pointer;
  }

  .messageWindow {
    border-bottom: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    background-color: #fafafa;
    padding: 1em;
    height: 100%;
    box-shadow: inset 1px 2px 6px -1px #7e7e7e;
    font-weight: bold;
    overflow-y: scroll;
    border-radius: 1em;
    &::-webkit-scrollbar{
      width: 12px
    }
    &::-webkit-scrollbar-track {
      margin: 10px 0
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .messageInfo {
    color: #999;
    text-align: center;
    display: block;
    text-transform: capitalize;
  }
  .inputBar {
    display: grid;
    grid-template-columns: 2fr 0fr;
    align-items: end;

    .inputMargin {
      margin-right: 1em;
    }

    .sendButton {
      color: #4595ff;
      font-size: 2em;
      cursor: pointer;

      &:hover {
        color: #255da5;
      }
    }
  }
}