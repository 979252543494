@import "../../../../styles/index.scss";

.orderSummary {
  position: relative;
  color: #47484b;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;

  &__map {
    height: 500px;
    flex-grow: 1;
    width: 100%;
    margin: 5px 0 10px;
    background: #ffffff;
  }

  &__description {
    margin-bottom: 10px;

    &--title {
      margin-bottom: 5px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subtitle {
      margin-top: 5px;
      font-size: 12px;
      color: #47484b;
    }

    &--close {
      position: absolute;
      font-size: 18px;
      top: 10px;
      right: 10px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }

  &__singleData {
    padding: 5px 20px 5px 0;
  }
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
  padding: 10px 10px 10px 2px;
  font-family: $open-sans;
  background: rgba(243, 243, 243, 0.7);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  color: #47484b;
}

.MuiTableCell-root .MuiTableCell-body .MuiTableCell-alignLeft {
  padding: 5px 5px 5px 2px;
  font-family: $open-sans;
  display: flex;
  align-items: center;
}
