@import "../../../../../styles/index.scss";

.formWork {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
  width: 200px;
  padding-bottom: 10px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar.MuiToolbar-gutters,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected,
.MuiTypography-root.MuiPickersClockNumber-clockNumber.MuiPickersClockNumber-clockNumberSelected.MuiTypography-body1,
.MuiTabs-root.MuiPickerDTTabs-tabs {
  background-color: $light-blue;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  .MuiButton-label {
    color: $light-blue;
  }
}

.MuiPickersClock-clock {
  .MuiPickersClock-pin {
    background-color: $light-blue;
  }

  .MuiPickersClockPointer-pointer {
    background-color: $light-blue;

    .MuiPickersClockPointer-thumb {
      border: 14px solid $light-blue;
    }
  }
}
