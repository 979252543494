@import "../../../../../styles/index";

.setRoute {
    position: absolute;
    background-color: #fff;
    width: 300px;
    min-height: 200px;
    top: 0;
    left: 0;


    &__maximalize {
        &--button {
            position: absolute !important;
            display: block;
            top: 7px !important;
            left: 32px;
            background-color: rgba(0, 60, 136, 0.5) !important;
            border-radius: 2px !important;
            padding: 1px !important;
            border: 3px solid rgba(255,255,255,0.73) !important;
        }

        &--buttonSettlements {
            margin-top: 1.6em !important;
            margin-left: 1.6em !important;
            position: absolute !important;
            display: block;
            background-color: rgba(0, 60, 136, 0.5) !important;
            border-radius: 2px !important;
            padding: 1px !important;
            border: 3px solid rgba(255,255,255,0.73) !important;
            z-index: 1;
        }

        &--icon {
            color: #fff;
            width: 17px !important;
            height: 17px !important;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 10px;

        &--title {
            margin: 0;
        }

        &--close {
            outline: none;
            border: none;
            background: none;
        }
    }

    &__form {
        position: relative;
        margin: 15px 0;
    }

    &__input {
        width: calc(100% - 20px) !important;
        margin: 5px 10px !important;
    }

    &__checkbox {
        width: calc(100% - 20px);
        margin: 10px !important;
    }

    &__control {
        margin: 0 !important;
        width: 100%;
    }

    &__info {
        margin: 10px !important;
        width: 100%;
    }
}

.setRouteSettlements {
    position: absolute;
    background-color: #fff;
    width: 300px;
    min-height: 200px;
    z-index: 9999;
}