@import "../../../styles/index.scss";

.formularz {
  @include formSectionStyles();
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__buttonWrapper {
    align-self: flex-start;
    margin-top: 30px;
  }
}
