@import "../../../styles/index.scss";

.zlecenie {
  min-height: 100vh;
  background-color: $globalBackground;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    background-color: white;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;
      margin-bottom: 20px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }

    &--about {
      padding: 10px 30px;

      &--title {
        margin: 5px;
        color: $light-blue;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        &--data {
          margin: 5px;
          color: $font-color;
          font-size: 16px;
          text-transform: none;
        }
      }
    }


    &--routeParts, &--panel {
      padding: 10px 30px;

      &--description {
        margin: 10px 5px;
        color: $font-color;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &--single, &--counter {
        margin-bottom: 10px;

        &--title {
          margin: 0 5px;
          color: $light-blue;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;

          &--data {
            margin-left: 5px;
            color: $font-color;
            font-size: 14px;
            text-transform: none;
          }
        }

        &--count {
          margin: 5px 5px 30px;
          color: $font-color;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }

    &--panel {
      padding: 10px 30px 40px;

      &--buttonWrapper {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .zlecenie {
    display: block;
    width: 100%;
    min-height: 0;
    box-shadow: none;

    &__wrapper {

      &--header {
        box-shadow: none;
        border-bottom: 2px solid #f6f6f6;
        margin-bottom: 10px;
      }

      &--about, &--routeParts, &--panel {
        padding: 10px 20px;

        &--description {
          margin: 5px 5px;
          font-size: 14px;
        }

        &--title {
          font-size: 12px;

          &--data {
            font-size: 14px;
          }
        }

        &--single, &--counter {
          margin-bottom: 5px;

          &--title {
            font-size: 12px;

            &--data {
              font-size: 14px;
            }
          }
        }
      }

      &--panel {
        padding: 10px 20px 40px;
      }
    }
  }
}