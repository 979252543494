@import "../../../../styles/index";
//.communicationPanelGrid {
  .communicationPanel {
    color: #47484b;
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    &__description {
      position: relative;

      &--title {
        font-size: 18px;
        line-height: 25px;
        font-weight: bold;
        margin: 0;
        padding: 5px 0;
      }

      &--close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          color: #00a0e3;
        }
      }
    }

    &__map {
      flex-grow: 1;
      height: 80vh;
      min-width: 250px;
      margin: 5px 0 10px;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--description {
        margin: 5px;

        &--title {
          font-size: 18px;
          color: $light-blue;
          text-transform: uppercase;
          font-weight: bold;
        }

        &--subTitle {
          font-size: 11px;
        }
      }
    }

    &__inputs {
      margin: 0 10px;
      color: #47484b;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      &--item,
      &--historyItem {
        position: relative;
        margin: 5px 0 10px;
        padding: 5px;
        background: #ffffff;
        border: 1px solid #d7d7d7;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

        &--title {
          margin: 5px 0;
          font-size: 15px;
          color: #47484b;
          text-transform: uppercase;
          font-weight: 600;
        }

        &--courseDetails,
        &--wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          min-width: 500px;
          max-width: 500px;

          &--singleColumn {
            width: 250px;
            display: flex;
            flex-direction: column;

            &--buttonWrapper {
              display: flex;
              flex-direction: column;
              align-self: flex-end;
              margin-right: 15px;
            }
          }

          &--info {
            margin: 0;
            color: $light-blue;
            font-size: 12px;
            font-weight: bold;
          }

          &--data {
            margin: 0 0 5px;
            font-size: 14px;
            font-weight: bold;
            max-width: 250px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }

          &--link {
            text-decoration: underline;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            margin-bottom: 8px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: #1dbbed;
            }
          }

          &--singleAddress {
            min-width: 250px;
            margin-bottom: 10px;

            &--info {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              color: $light-blue;
            }

            &--data {
              margin: 0 0 5px 0;
              padding: 0;
              font-weight: bold;
              font-size: 14px;
              line-height: 18px;
              color: #575b55;
            }
          }

          &--time {
            display: flex;
            justify-content: flex-start;

            &--single,
            &--from,
            &--to {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 20px 10px 0;
              min-width: 250px;

              &--data {
                font-weight: bold;
                font-size: 13px;
                color: #575b55;
                max-width: 250px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;

                &--edited {
                  margin: 0 0 2px 0;
                  font-size: 12px;
                  font-weight: bold;
                  //color: #FF650F;
                }
              }

              &--toWork,
              &--toHome,
              &--totalTime,
              &--totalKm,
              &--toHome--edited,
              &--toWork--edited {
                width: 22px;
                height: 22px;
                margin-right: 5px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
              }

              &--totalTime {
                background-image: url("../../../../img/time_icon.svg");
              }

              &--totalKm {
                background-image: url("../../../../img/totalkm_icon.svg");
              }
            }

            &--from {
              &--toHome {
                background-image: url("../../../../img/Group 1027.svg");
              }

              &--toWork {
                background-image: url("../../../../img/Group 1031.svg");
              }

              &--toHome--edited {
                background-image: url("../../../../img/home_icon.svg");
              }

              &--toWork--edited {
                background-image: url("../../../../img/work_icon.svg");
              }
            }

            &--to {
              &--toHome {
                background-image: url("../../../../img/Group 1032.svg");
              }

              &--toWork {
                background-image: url("../../../../img/Group 1033.svg");
              }

              &--toHome--edited {
                background-image: url("../../../../img/home_icon.svg");
              }

              &--toWork--edited {
                background-image: url("../../../../img/work_icon.svg");
              }
            }
          }
        }
      }

      &--historyItem {
        border: none;
        box-shadow: none;
        padding: 0;

        &--wrapper {
          flex-direction: column;
          margin: 0;
          padding: 0;
          min-width: 250px;
          max-width: 280px;

          &--time,
          &--courseDetails,
          &--wrapper {
            flex-direction: column;
          }

          &--history {
            padding: 0;
            margin: 0;

            &--details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &--title {
                margin: 0 0 3px 0;
                font-size: 13px;
                color: #47484b;
                text-transform: uppercase;
                font-weight: 600;

                &--history {
                  margin: 0 0 3px 0;
                  font-size: 13px;
                  color: #1dbbed;
                  text-transform: uppercase;
                  font-weight: 600;
                }
              }

              &--singleColumn {
                margin-bottom: 10px;

                &--info {
                  margin: 0;
                  color: #6f6f6f;
                  font-size: 12px;
                  font-weight: bold;
                }

                &--data {
                  margin: 0 0 4px 0;
                  font-size: 12px;
                  font-weight: bold;
                  max-width: 250px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;

                  &--history {
                    margin: 0 0 4px 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: #1dbbed;
                  }

                  &--edited {
                    margin: 0 0 2px 0;
                    font-size: 12px;
                    font-weight: bold;
                    max-width: 250px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    //color: #FF650F;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__times {
      &--row {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &--col {
        padding: 3px 0;
        &:not(:first-child) {
          padding: 2px 10px;
        }

        &--location {
          width: 60%;
          padding: 3px 0;
          &:not(:first-child) {
            padding: 2px 10px;
          }
        }

        &--time {
          width: 20%;
          padding: 3px 0;
          &:not(:first-child) {
            padding: 2px 10px;
          }
        }

        &--title {
          padding: 10px 0;
          &:not(:first-child) {
            padding: 10px;
          }
          font-weight: 600;

          &--location {
            width: 60%;
            padding: 10px 0;
            &:not(:first-child) {
              padding: 10px;
            }
            font-weight: 600;
          }

          &--time {
            width: 20%;
            padding: 10px 0;
            &:not(:first-child) {
              padding: 10px;
            }
            font-weight: 600;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
//}
