@import "../../../styles/index.scss";

.active, .archive {
  @include formSectionStyles();
  max-height: calc(100vh - 10px - 48px - 64px);
  overflow-y: auto;


  &.operator {
    overflow-x: hidden;
    margin: 0;
    padding: 10;
  }

  &__noData {
    min-width: 200px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  &__startMetaTime {
    &--single {
      padding: 5px;
      line-height: 22px;
    }
  }

  &__description {
    padding: 10px 10px 30px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--title {
      margin: 0;
      padding-right: 30px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--button {
      margin: 10px 50px 10px 0;
    }

    &--subTitle {
      margin: 0;
      font-size: 11px;
      color: #47484b;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;

      &--legendItem {
        margin-right: 15px;
        display: flex;
        align-items: center;

        &--singleLegend {
          font-size: 11px;
          text-transform: uppercase;
          color: #47484b;

          &--description {
            font-size: 11px;
            text-transform: uppercase;
            color: #47484b;
            line-height: 22px;
          }
        }
      }
    }
  }

  &__table {
    &--item {
      margin: 0;
      padding: 0;
      color: #47484b;
      display: flex;
      flex-direction: column;
      text-transform: capitalize;

      &--single {
        padding: 5px 0;
      }

      &--counterWrapper, &--startMetaTime {
        &--single {
          display: flex;
          align-items: center;
          padding: 5px;
        }
      }

      &--deny {
        display: flex;
        flex-direction: column;
        justify-content: center;


        &--data {
          font-weight: bold;
        }

        &--alert {
          color: #ED1D1D;
          font-weight: bold;
        }
      }

      &--wTrasie {
        display: flex;
        flex-direction: column;
        justify-content: center;


        &--data {
          font-weight: bold;
        }

        &--alert {
          color: #66D01D;
          font-weight: bold;
        }
      }

      &--frozen {
        display: flex;
        flex-direction: column;
        justify-content: center;


        &--data {
          font-weight: bold;
        }

        &--alert {
          color: #1DBBED;
          font-weight: bold;
        }
      }

      &--przypisany {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--data {
          font-weight: bold;
        }

        &--alert {
          color: #faba1f;
          font-weight: bold;
        }
      }

      &--toWork,
      &--toHome {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &--toHome {
        background-image: url("../../../img/home_icon.svg");
      }

      &--toWork {
        background-image: url("../../../img/work_icon.svg");
      }
    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: nowrap;
  }
}
