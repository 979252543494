@import "../../../../styles/index.scss";

.formLine {
  &__order, &__proposition, &__return, &__subOrder {
    border: none;
    border-left: 2px solid $light-blue;
    margin-top: 10px;
    padding: 20px;
    background: $order-background;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 1600px;

    &--formWrapper {
      display: flex;

      &--formMap {
        flex-grow: 1;
        margin: 5px;

        &--title {
          font-size: 16px;
          color: $light-blue;
          text-transform: uppercase;
          font-weight: bold;
          margin: 0
        }
      }

      &--form {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        margin-right: 20px;

        &--title {
          margin: 0 0 0 5px;
          font-size: 16px;
          color: $light-blue;
          text-transform: uppercase;
          font-weight: bold;
        }

        &--single {
          display: flex;
          justify-content: space-between;
          margin: 5px;

          &--direction {
            display: flex;
            align-items: center;
          }
        }

        &--buttonWrapper {
          display: flex;
          justify-content: space-between;

          &--direction {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &--returnButtonWrapper {
            display: flex;
            align-content: center;
            align-items: center;

            &--description {
              margin: 0 5px;

            }
          }

          &--buttonGroup {
            display: flex;
            justify-content: flex-end;

            &--removeButton, &--acceptButton, &--confirmButton {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &__return, &__subOrder {
    margin-top: 10px;
    padding: 20px;
    margin-left: 100px;
    background-color: $proposal-background;

    &--formWrapper--form--buttonWrapper {
      justify-content: space-between;

      &--buttonGroup--acceptButton {
        display: none;
      }
    }
  }

  &__order, &__subOrder {
    &--formWrapper--form--buttonWrapper--buttonGroup--confirmButton {
      display: none;
    }
  }

  &__subOrder {
    background-color: $order-background;
  }
}




