@import "../../../styles/index.scss";

.orderPanel__wrapper {
  @include formSectionStyles();
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  &--loading {
    justify-content: center;
    align-items: center;
  }

  .orderPanel__buttonWrapper {
    align-self: flex-start;
    margin-top: 30px;
  }
}
