@import "../../../../../styles/index.scss";

.formHome {
  display: flex;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
  width: 200px;

  &__input {
    padding: 5px;

    &--link {
      margin: 0 0 8px 5px;
      text-decoration: underline;
      font-weight: normal;
      font-size: 10px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #1dbbed;
      }
    }
  }
}
