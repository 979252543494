@import "../../../styles/index.scss";

.workerDetails {
  color: #47484b;
  position: relative;
  display: flex;
  justify-content: stretch;
  overflow-y: auto;
  height: 100%;

  &__map {
    min-width: 400px;
    height: auto;
    width: 100%;
    padding: 5px 10px;
    flex-grow: 1;
  }

  &__noData {
    min-width: 200px;
    padding: 5px;
    background-color: white;
    color: #6e6e6e;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  &__description {
    &--title {
      margin: 0;
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
    }

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: #00a0e3;
      }
    }
  }

  &__inputs {
    &--item {
      margin: 5px 0;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);

      &--title {
        margin: 5px 0;
        font-size: 14px;
        color: #47484b;
        font-weight: 600;
      }

      &--id {
        margin: 0;
        color: $light-blue;
        font-size: 12px;
        font-weight: 600;
      }

      &--idInfo {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }

      &--person, &--contact, &--address {
        &--info {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: $light-blue;
        }

        &--data {
          margin: 0 0 5px 0;
          padding: 0;
          font-weight: bold;
          font-size: 14px;
          color: $font-color;
        }
      }

      &--address {
        display: flex;

        &--single {
          min-width: 250px;

          &--info {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: $light-blue;
          }

          &--data {
            margin: 0 0 5px 0;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            color: $font-color;
          }
        }
      }


    }
  }
}
