@import "../../../../styles/index";

.addressList {
  @include formSectionStyles();

  &__noData {
    min-width: 200px;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  &__description {
    padding: 10px 10px 30px 0;

    &--title {
      margin: 0;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;

    }

    &--subTitle {
      margin: 0;
      font-size: 11px;
      color: #47484b;
    }
  }

  &__table {
    border: none;

    &--item {
      margin: 0;
      padding: 0;
      color: #47484b;


      &--time {
        border: none;

        &--single {
          display: flex;
          align-items: center;
          padding: 5px 5px 5px 0;

          &--toWork,
          &--toHome {
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &--buttonWrapper {
      display: flex;
      flex-wrap: nowrap;

      &--moreButton,
      &--confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        height: 30px;
        width: 30px;
        border: none;
        border-radius: 15px;
        background-color: #1DBBED;
        color: white;
        transition: 0.3s;

        &:hover {
          background-color: #00A0E3;
        }
      }

      &--confirmButton {
        background-color: #66d01d;

        &:hover {
          background-color: #5AB71B;
        }
      }
    }
  }
}




