@import "../../../styles/index.scss";

.rozliczenia {
  @include formSectionStyles();
  min-height: 0;
  background-color: white;
  padding-bottom: 0;

  .legend {
    padding-left: 1em;
    display: flex;
    justify-content: flex-start;

    .item {
      margin-right: 15px;
      display: flex;
      align-items: center;

      .single {
        font-size: 11px;
        text-transform: uppercase;
        color: #47484b;
      }
    }
  }

  &__description {
    padding: 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--title {
      margin: 0;
      padding-right: 30px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--button {
      margin: 10px 50px 10px 0;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;

      &--legendItem {
        margin-right: 15px;
        display: flex;
        align-items: center;

        &--singleLegend {
          font-size: 11px;
          text-transform: uppercase;
          color: #47484b;

          &--description {
            font-size: 11px;
            text-transform: uppercase;
            color: #47484b;
            line-height: 22px;
          }
        }
      }
    }
  }

  &__wrapper {
    padding: 10px;

    &--dayList {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 30px;

      &--description {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: $font-color;
        padding-right: 10px;
      }
    }
  }
}
