@import "../../../../styles/index.scss";

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  position: fixed;
  top: 100px;
  width: 450px;
  padding: 15px;
  border-radius: 0;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

div.MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 0;
}
.actionBlocker {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__close {
    position: absolute;
    font-size: 20px;
    top: 5px;
    right: 5px;
    cursor: pointer;
    &:hover {
      color: #00a0e3;
    }
  }

  &__content {
    &--description {
      padding: 8px 24px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 25px;
      font-weight: bold;
    }
    &--item {
      margin: 5px 0 10px;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);
      &--singleData {
        margin: 2px 0;
        font-size: 15px;
        line-height: 20px;
      }
    }

    &--buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 8px 24px;
    }
  }
}
