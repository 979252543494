@import "../../../../styles/index.scss";

.drivers {
  @include formSectionStyles();
  padding: 5px;

  .driversWrapper {
    background-color: white;
  }

  &__description {
    padding: 10px;
    display: flex;

    &--title {
      padding-right: 30px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      font-size: 11px;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--legendItem {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;

        &--singleLegend {
          margin-right: 20px;
          font-size: 11px;
          text-transform: uppercase;

          &--icon {
            height: 1em;
            width: 1em;
            margin-right: 5px;
            border-radius: 25px;
            background-image: url("./../../../../img/availableIcon.png");
            background-position: center;
            background-size: cover;
          }
        }
      }
    }
  }

  &__dateSwitcher {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: 15%;
    margin: 10px;
    padding: 5px;
    border: 1px solid #d7d7d7;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

    &--calendarIcon {
      height: 25px;
      width: 25px;
      margin-right: 5px;
      background-image: url("./../../../../img/calendar.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__singleLine {
    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    margin: 10px 0 40px;

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 5px;
      flex-basis: 15%;
      max-width: 200px;

      &--name {
        margin: 0;
        font-size: 16px;
      }
    }

    &--schedule {
      flex-grow: 1;
      position: relative;
      max-width: 1000px;
      height: 35px;
      border: 1px solid #d7d7d7;
      background-image: url("../../../../img/scheduleThinLine.svg");
      background-position: center;
      background-size: contain;

      &--hours {
        position: absolute;
        bottom: 0;
        width: 100%;

        &--item {
          font-size: 12px;
          position: absolute;
          top: 0;
          z-index: 5;
        }
      }

      &--onRoute,
      &--wait,
      &--dayOff,
      &--rest {
        position: absolute;
        top: 0;
        height: 100%;
      }

      &--onRoute {
        background-color: $light-blue;
      }

      &--wait {
        background-color: #d7d7d7;
      }

      &--dayOff {
        background-color: #d7d7d7;
      }

      &--rest {
        background-color: #ed1d1d;
      }
    }

    &--buttonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      height: 40px;
      width: 40px;

      &--moreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 30px;
        width: 30px;
        border: none;
        border-radius: 15px;
        background-color: #1DBBED;
        color: white;
        transition: 0.3s;

        &:hover {
          background-color: #00A0E3;
        }
      }
    }
  }
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
  background-color: $light-blue;
}
