@import "./../../../styles/index.scss";

.tableList {
  margin: 10px;
  font-family: $open-sans;
  color: #47484b;


  &__error {
    color: red;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
  }

  &__description {
    padding: 10px 10px 30px 10px;

    &--title {
      margin: 0;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      font-size: 11px;
      margin: 0;
    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: nowrap;

    &--button,
    &--removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border: none;
      border-radius: 15px;
      background-color: #1DBBED;
      color: white;
      transition: 0.3s;

      &:hover {
        background-color: #00A0E3;
      }
    }

    &--removeButton {
      background-color: #ED1D1D;

      &:hover {
        background-color: #C01313;
      }
    }
  }
}
