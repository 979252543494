@import "../../../../../styles/index.scss";

.orderWorker {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__singleLine, &__singleLine__deleted, &__singleLine__new, &__singleLine__touched {
    min-height: $order-input-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
    margin: 5px;
    padding: 5px;

    &__deleted {
      border-left: 3px solid red;
    }

    &__new {
      border-left: 3px solid lightgreen;
    }
    &__touched {
      border-left: 3px solid #1dbbed;
    }

    &--buttonWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 5px;
      height: 35px;

      &--description {
        font-size: 11px;
        font-weight: normal;
        line-height: 30px;
        color: #47484b;

        &--container {
          &--close {
            cursor: pointer;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }

      &--addButton,
      &--removeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2px;
        height: 30px;
        width: 30px;
        border: none;
        border-radius: 15px;
        background-color: #b4b4b4;
        color: white;
        transition: 0.3s;

        &:hover {
          background-color: #777777;
        }
      }

      &--removeButton {
        background-color: #777777;

        &:hover {
          background-color: black;
        }
      }
    }
  }
}
