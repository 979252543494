@import "../../../../styles/index.scss";

.unfillCourses {
    @include formSectionStyles();

    &__modal {
        &__description {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            &--title {
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }

            &--close {
                position: absolute;
                right: 10px;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    color: #00a0e3;
                }
            }
        }
    }

    &__autoComplete {
        display: flex;
        flex-direction: column;

        &--description {
            color: gray;
            font-size: 12px;
        }
    }

    &__noData {
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
    }

    &__description {
        padding: 10px 10px 30px 10px;

        &--title {
            font-size: 18px;
            color: $light-blue;
            text-transform: uppercase;
            font-weight: bold;
        }

        &--subTitle {
            font-size: 11px;
            color: #47484b;
        }
    }

    &__table {
        border: none;

        &--item {
            margin: 0;
            padding: 0;
            color: #47484b;

            &--time {
                border: none;

                &--single {
                    display: flex;
                    align-items: center;
                    padding: 5px 5px 5px 0;

                    &--toWork,
                    &--toHome {
                        width: 22px;
                        height: 22px;
                        margin-right: 10px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    &--toHome {
                        background-image: url("../../../../img/home_icon.svg");
                    }

                    &--toWork {
                        background-image: url("../../../../img/work_icon.svg");
                    }
                }
            }
        }

        &--buttonWrapper {
            display: flex;
            flex-wrap: nowrap;

            &--moreButton,
            &--confirmButton {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
                height: 30px;
                width: 30px;
                border: none;
                border-radius: 15px;
                background-color: #1dbbed;
                color: white;
                transition: 0.3s;

                &:hover {
                    background-color: #00a0e3;
                }
            }

            &--confirmButton {
                background-color: #66d01d;

                &:hover {
                    background-color: #5ab71b;
                }
            }
        }
    }
}
