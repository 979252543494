@import "../../../../styles/index";

.moreDetails {
    color: #47484b;
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    &__description {
        position: relative;

        &--title {
            font-size: 18px;
            line-height: 25px;
            font-weight: bold;
            margin: 0 5px;
            padding: 5px 0;
        }

        &--close {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                color: #00a0e3;
            }
        }
    }

    &__inputs {
        margin: 0;
        color: #47484b;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        &--item {
            flex-basis: 50%;
            position: relative;
            margin: 5px;
            padding: 5px;
            background: #ffffff;
            border: 1px solid #d7d7d7;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07);
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            &--singleColumn {
                display: flex;
                flex-wrap: wrap;

                &--title {
                    width: 100%;
                    margin: 0 0 10px 0;
                    font-size: 14px;
                    color: #47484b;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                &--single {
                    flex-basis: 50%;

                    &--settlement {
                        flex-basis: 33.33%;
                    }

                    &--notes {
                        flex-basis: 100%;
                    }

                    &--info {
                        margin: 0;
                        color: $light-blue;
                        font-size: 12px;
                        font-weight: bold;
                    }

                    &--info--highway {
                        margin: 0;
                        color: $light-blue;
                        font-size: 12px;
                        font-weight: bold;
                        min-height: 34px;
                    }

                    &--data {
                        margin: 0 0 10px;
                        font-size: 14px;
                        font-weight: bold;
                        max-width: 100%;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}
