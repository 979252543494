$globalBackground: #e2e2e2;
$aside-menu-color: #47484b;
$picked-aside-menu-color: #313131;
$order-background: white;
$proposal-background: #f3f3f3;
$font-color: #47484b;

$light-blue: #1dbbed;
$light-grey: #b4b4b4;
$dark-grey: #777777;
