@import "../../../styles/index.scss";

.active, .archive {
  @include formSectionStyles();

  &__noData {
    min-width: 200px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  &__startMetaTime {
    &--single {
      padding: 5px;
      line-height: 22px;
    }
  }

  &__description {
    padding: 10px 10px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--title {
      margin: 0;
      padding-right: 30px;
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      margin: 0;
      font-size: 11px;
      color: #47484b;
    }

    &--button {
      margin: 10px 50px 10px 0;

    }

    &--legend {
      display: flex;
      justify-content: flex-start;
      padding: 10px 30px;

      &--legendItem {
        display: flex;
        align-items: center;
        padding-right: 5px;

        &--singleLegend {
          font-size: 11px;
          text-transform: uppercase;
          color: #47484b;
          margin: 0;

        }
      }
    }
  }

  &__table {
    &--item {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &--single {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #47484b;
        min-height: 40px;

        &--home, &--middle {
          height: 16px;
          width: 26px;
          margin-left: 20px;
          margin-right: 20px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../../img/totalkm_icon.svg");
        }

        &--home {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../../img/home_icon.svg");
        }

        &--data {
          margin-right: 20px;
        }
      }
    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 10px;
  }
}