@import "../../../styles/index";

.singleDriverSchedule {
  background: $order-background;
  color: $font-color;
  padding: 5px;

  &__description {
    &--title {
      font-size: 18px;
      color: $light-blue;
      text-transform: uppercase;
      font-weight: bold;
    }

    &--subTitle {
      font-size: 11px;
    }

    &--legend {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--legendItem {
        margin: 0 15px 0 0;
        display: flex;
        align-items: center;

        &--singleLegend {
          font-size: 11px;
          text-transform: uppercase;

          &--icon, &--icon2 {
            height: 1em;
            width: 1em;
            margin-right: 5px;
            border-radius: 25px;
            background-image: url("../../../img/availableIcon.png");
            background-position: center;
            background-size: cover;
          }

          &--icon2 {
            background-color: #1DBBED;
          }
        }
      }
    }
  }

  &__scheduleWrapper {
    width: 90%;
    height: 30px;
    position: relative;
    margin: 10px 0 30px;

    &--hours--item {
      position: absolute;
      bottom: -20px;
      font-size: 12px;
      z-index: 5;
    }

    &--schedule {
      display: flex;
      justify-content: stretch;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      border: 1px solid #d7d7d7;
      background-image: url("../../../img/scheduleThinLine.svg");
      background-position: center;
      background-size: contain;

      &--onRoute, &--dayOff {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: $light-blue;
        border: 2px solid #e6e6e6
      }

      &--dayOff {
        background-color: #d7d7d7;
      }
    }
  }
}