@import "../../../styles/index";

.changeDriverPanel {
  font-family: $open-sans;
  background: $order-background;
  color: #47484b;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &__autoComplete {
    display: flex;
    flex-direction: column;

    &--description {
      color: gray;
      font-size: 12px;
    }
  }

  &__input {
  }

  &__button {
    flex-grow: 1;
  }
}